// extracted by mini-css-extract-plugin
export var barContainer = "UsersDashboardNavbar__barContainer__cW_hN";
export var barWrapper = "UsersDashboardNavbar__barWrapper__gfDrI";
export var column = "UsersDashboardNavbar__column__XLSV5";
export var flex = "UsersDashboardNavbar__flex__oiCe3";
export var flexColumn = "UsersDashboardNavbar__flexColumn__czheD";
export var gap1 = "UsersDashboardNavbar__gap1__ZGL6K";
export var gap2 = "UsersDashboardNavbar__gap2__El6P9";
export var gap3 = "UsersDashboardNavbar__gap3__CgO8z";
export var gap4 = "UsersDashboardNavbar__gap4__nSB4O";
export var gap5 = "UsersDashboardNavbar__gap5__HIWuM";
export var linkContainer = "UsersDashboardNavbar__linkContainer__rFUB4";
export var linkItem = "UsersDashboardNavbar__linkItem__pkjQJ";
export var row = "UsersDashboardNavbar__row__LwbRK";
export var underlinedLink = "UsersDashboardNavbar__underlinedLink__jOSLI";